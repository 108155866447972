var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "edit-exchange" },
    [
      _c(
        "el-form",
        {
          attrs: {
            "label-position": "left",
            model: _vm.formData,
            "label-width": "80px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { required: "", label: "活动时间" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    disabled: _vm.type == "view",
                    size: "mini",
                    placeholder: "请选择",
                  },
                  model: {
                    value: _vm.formData.validType,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "validType", $$v)
                    },
                    expression: "formData.validType",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "指定日期", value: 0 } }),
                  _c("el-option", {
                    attrs: { label: "指定日期和时间", value: 1 },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "" } }, [
            _vm.formData.validType == 0
              ? _c(
                  "div",
                  [
                    _c("el-date-picker", {
                      attrs: {
                        size: "mini",
                        disabled: _vm.type == "view",
                        "value-format": "timestamp",
                        "start-placeholder": "开始时间",
                        "end-placeholder": "结束时间",
                        type: "datetimerange",
                        "default-time": ["00:00:00", "23:59:59"],
                      },
                      on: { change: _vm.changeDate },
                      model: {
                        value: _vm.dateRange,
                        callback: function ($$v) {
                          _vm.dateRange = $$v
                        },
                        expression: "dateRange",
                      },
                    }),
                  ],
                  1
                )
              : _c("div", [
                  _c(
                    "div",
                    [
                      _c("span", { staticStyle: { "margin-right": "10px" } }, [
                        _vm._v("起止日期"),
                      ]),
                      _c("el-date-picker", {
                        attrs: {
                          size: "mini",
                          disabled: _vm.type == "view",
                          "start-placeholder": "开始日期",
                          "value-format": "timestamp",
                          "end-placeholder": "结束日期",
                          type: "daterange",
                        },
                        on: { change: _vm.changeDate },
                        model: {
                          value: _vm.dateRange,
                          callback: function ($$v) {
                            _vm.dateRange = $$v
                          },
                          expression: "dateRange",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("span", { staticStyle: { "margin-right": "10px" } }, [
                        _vm._v("每日起止时间"),
                      ]),
                      _c("el-time-picker", {
                        staticStyle: { width: "400px" },
                        attrs: {
                          disabled: _vm.type == "view",
                          "is-range": "",
                          "start-placeholder": "开始时间",
                          "end-placeholder": "结束时间",
                          "time-arrow-control": "",
                          size: "mini",
                        },
                        on: { change: _vm.changeTimeArea },
                        model: {
                          value: _vm.dateTimeList,
                          callback: function ($$v) {
                            _vm.dateTimeList = $$v
                          },
                          expression: "dateTimeList",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
          ]),
          _c(
            "el-form-item",
            { attrs: { required: "", label: "参与条件" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.radio,
                    callback: function ($$v) {
                      _vm.radio = $$v
                    },
                    expression: "radio",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 3 } }, [
                    _vm._v("购买指定商品"),
                  ]),
                  _c("el-radio", { attrs: { label: 6, disabled: "" } }, [
                    _vm._v("购买指定金额"),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                [
                  _vm.type != "view"
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: { click: _vm.showMainGoods },
                        },
                        [_vm._v("选择商品")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    border: "",
                    size: "small",
                    "header-cell-style": {
                      background: "#d9dde1",
                      color: "#0d0202",
                    },
                    data: _vm.exchangeMainGoodsSkuDtoList,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "goodsId",
                      label: "商品ID",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "goodsName",
                      label: "商品名称",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", prop: "skuName", label: "规格" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "stockQuantity",
                      label: "销售库存",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "sellPrice",
                      label: "售价",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "date",
                      label: "操作",
                      width: "180",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row, $index }) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  disabled: _vm.type == "view",
                                  type: "text",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeMainGood(row, $index)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { required: "", label: "换购商品" } },
            [
              _c(
                "div",
                [
                  _vm.type != "view"
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: { click: _vm.showExchangeGoods },
                        },
                        [_vm._v("选择商品")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    border: "",
                    data: _vm.exchangeRelationGoodsSkuDtoList,
                    size: "small",
                    "header-cell-style": {
                      background: "#d9dde1",
                      color: "#0d0202",
                    },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "goodsId",
                      label: "商品ID",
                      width: "180",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "goodsName",
                      label: "商品名称",
                      width: "180",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", prop: "skuName", label: "规格" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "stockQuantity",
                      label: "销售库存",
                      width: "180",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "exchangeStock",
                      label: "活动库存",
                      width: "220",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c("el-input-number", {
                              attrs: {
                                size: "small",
                                disabled: _vm.type == "view",
                                min: 0,
                                max: row.stockQuantity,
                                label: "描述文字",
                              },
                              model: {
                                value: row.exchangeStock,
                                callback: function ($$v) {
                                  _vm.$set(row, "exchangeStock", $$v)
                                },
                                expression: "row.exchangeStock",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "sellPrice",
                      label: "售价",
                      width: "180",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "exchangePrice",
                      label: "活动价格",
                      width: "220",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c("el-input-number", {
                              attrs: {
                                disabled: _vm.type == "view",
                                min: 0,
                                size: "small",
                                label: "描述文字",
                              },
                              model: {
                                value: row.exchangePrice,
                                callback: function ($$v) {
                                  _vm.$set(row, "exchangePrice", $$v)
                                },
                                expression: "row.exchangePrice",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "date",
                      label: "操作",
                      width: "180",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row, $index }) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  disabled: _vm.type == "view",
                                  type: "text",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeExchangeGood(row, $index)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "" } },
            [
              _vm.type != "view"
                ? _c(
                    "el-button",
                    {
                      attrs: { loading: _vm.saveLoad, type: "primary" },
                      on: { click: _vm.confirm },
                    },
                    [_vm._v(_vm._s(_vm.type == "edit" ? "确定" : "创建"))]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$router.go(-1)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.type == "view" ? "返回" : "取消"))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("GoodsModal", {
        attrs: { selectGoods: _vm.selectDic },
        on: { select: _vm.selectGood },
        model: {
          value: _vm.showGoodsModal,
          callback: function ($$v) {
            _vm.showGoodsModal = $$v
          },
          expression: "showGoodsModal",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }