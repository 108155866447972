<template>
  <div class="edit-exchange">
    <el-form label-position="left" :model="formData" label-width="80px">
      <el-form-item required label="活动时间">
        <el-select
          :disabled="type == 'view'"
          size="mini"
          v-model="formData.validType"
          placeholder="请选择"
        >
          <el-option label="指定日期" :value="0"> </el-option>
          <el-option label="指定日期和时间" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <div v-if="formData.validType == 0">
          <el-date-picker
            size="mini"
            @change="changeDate"
            :disabled="type == 'view'"
            v-model="dateRange"
            value-format="timestamp"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            type="datetimerange"
            :default-time="['00:00:00', '23:59:59']"
          >
          </el-date-picker>
        </div>
        <div v-else>
          <div>
            <span style="margin-right: 10px">起止日期</span>
            <el-date-picker
              size="mini"
              :disabled="type == 'view'"
              @change="changeDate"
              start-placeholder="开始日期"
              value-format="timestamp"
              end-placeholder="结束日期"
              v-model="dateRange"
              type="daterange"
            >
            </el-date-picker>
          </div>
          <div>
            <span style="margin-right: 10px">每日起止时间</span>
            <el-time-picker
              :disabled="type == 'view'"
              @change="changeTimeArea"
              v-model="dateTimeList"
              is-range
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              time-arrow-control
              size="mini"
              style="width: 400px"
            />
          </div>
        </div>
      </el-form-item>
      <el-form-item required label="参与条件">
        <el-radio-group v-model="radio">
          <el-radio :label="3">购买指定商品</el-radio>
          <el-radio :label="6" disabled>购买指定金额</el-radio>
        </el-radio-group>
        <div>
          <el-button v-if="type != 'view'" @click="showMainGoods" type="text"
            >选择商品</el-button
          >
        </div>
        <el-table
          border
          style="width: 100%"
          size="small"
          :header-cell-style="{
            background: '#d9dde1',
            color: '#0d0202',
          }"
          :data="exchangeMainGoodsSkuDtoList"
        >
          <el-table-column align="center" prop="goodsId" label="商品ID">
          </el-table-column>
          <el-table-column align="center" prop="goodsName" label="商品名称">
          </el-table-column>
          <el-table-column
            align="center"
            prop="skuName"
            label="规格"
          ></el-table-column>
          <el-table-column align="center" prop="stockQuantity" label="销售库存">
          </el-table-column>
          <el-table-column align="center" prop="sellPrice" label="售价">
          </el-table-column>
          <el-table-column align="center" prop="date" label="操作" width="180">
            <template slot-scope="{ row, $index }">
              <el-button
                :disabled="type == 'view'"
                @click="removeMainGood(row, $index)"
                type="text"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item required label="换购商品">
        <div>
          <el-button
            v-if="type != 'view'"
            @click="showExchangeGoods"
            type="text"
            >选择商品</el-button
          >
        </div>
        <el-table
          border
          style="width: 100%"
          :data="exchangeRelationGoodsSkuDtoList"
          size="small"
          :header-cell-style="{
            background: '#d9dde1',
            color: '#0d0202',
          }"
        >
          <el-table-column
            align="center"
            prop="goodsId"
            label="商品ID"
            width="180"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="goodsName"
            label="商品名称"
            width="180"
          >
          </el-table-column>
          <el-table-column align="center" prop="skuName" label="规格">
          </el-table-column>
          <el-table-column
            align="center"
            prop="stockQuantity"
            label="销售库存"
            width="180"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="exchangeStock"
            label="活动库存"
            width="220"
          >
            <template slot-scope="{ row }">
              <el-input-number
                size="small"
                v-model="row.exchangeStock"
                :disabled="type == 'view'"
                :min="0"
                :max="row.stockQuantity"
                label="描述文字"
              ></el-input-number>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="sellPrice"
            label="售价"
            width="180"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="exchangePrice"
            label="活动价格"
            width="220"
          >
            <template slot-scope="{ row }">
              <el-input-number
                v-model="row.exchangePrice"
                :disabled="type == 'view'"
                :min="0"
                size="small"
                label="描述文字"
              ></el-input-number>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="date" label="操作" width="180">
            <template slot-scope="{ row, $index }">
              <el-button
                :disabled="type == 'view'"
                @click="removeExchangeGood(row, $index)"
                type="text"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>

      <el-form-item label="">
        <el-button
          v-if="type != 'view'"
          :loading="saveLoad"
          @click="confirm"
          type="primary"
          >{{ type == "edit" ? "确定" : "创建" }}</el-button
        >
        <el-button @click="$router.go(-1)">{{
          type == "view" ? "返回" : "取消"
        }}</el-button>
      </el-form-item>
    </el-form>

    <GoodsModal :selectGoods="selectDic" @select="selectGood" v-model="showGoodsModal"></GoodsModal>
  </div>
</template>

<script>
import { addExchangeOrder, exchangeDetail, editExchange } from "@/api/order";
import GoodsModal from "./GoodsModal.vue";
import {dateFormat} from '@/utils/utils'
export default {
  components: {
    GoodsModal,
  },
  data() {
    return {
      selectDic: {},
      showGoodsModal: false, //商品弹框
      radio: 3,
      modalType: "main", //main exchange
      saveLoad: false, //保存laod
      exchangeMainGoodsSkuDtoList: [], //主商品
      exchangeRelationGoodsSkuDtoList: [], //换购商品
      formData: {
        endTime: "",
        exchangeStatus: 0,
        id: 0,
        perDayEndTime: 0,
        perDayStartTime: 0,
        startTime: "",
        status: 1,
        suppliersId: 0,
        validType: 0,
      },
      dateRange: [],
      dateTimeList: [new Date(), new Date()],
      editId: "",
      type: "", // add: 新增  view: 查看 edit: 编辑
    };
  },
  created() {
    this.type = this.$route.query.type;
    if(this.type == 'view') {
      window.document.title = '查看';
    } else if(this.type == 'add') {
      window.document.title = '新增';
    } else {
      window.document.title = '编辑'; 
    }
    this.editId = this.$route.query.id;
  },
  mounted() {
    if (this.type != "add") {
      this.getDetail();
    }
  },
  methods: {
    getDetail() {
      exchangeDetail({ exchangeId: this.editId }).then((res) => {
        if (res.code === 0) {
          this.formData = res.data;
          this.exchangeMainGoodsSkuDtoList =
            res.data.exchangeMainGoodsSkuDtoList;
          this.exchangeRelationGoodsSkuDtoList =
            res.data.exchangeRelationGoodsSkuDtoList;
          this.dateRange = [
            new Date(res.data.startTime),
            new Date(res.data.endTime),
          ];

          this.initDateTime(res.data);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    changeTimeArea(val) {
      // console.log(this.dateTimeList, newDate(this.dateTimeList), dateFormat(this.dateTimeList[0], 'yyyy-MM-dd hh:mm:ss'))
    },
    initDateTime(row) {
      const {perDayStartTime, perDayEndTime} = row;
      let zeroTime = new Date(new Date().setHours(0,0,0,0)).valueOf();
      let start = new Date(+perDayStartTime + zeroTime);
      let end = new Date(+perDayEndTime + zeroTime);
      this.dateTimeList = [start, end];
    },
    changeDate(date) {
      if (date) {
        this.formData.startTime = date[0];
        this.formData.endTime = date[1];
      } else {
        this.formData.startTime = "";
        this.formData.endTime = "";
      }
    },
    selectGood(good) {
      if (this.modalType == "main") {
        let oldGood = this.exchangeMainGoodsSkuDtoList.find(
          (e) => e.skuId == good.skuId
        );

        if (oldGood) {
          this.$message.info("已有该商品");
          return;
        }

        this.exchangeMainGoodsSkuDtoList.push(good);
      } else {
        let exchangeGood = this.exchangeRelationGoodsSkuDtoList.find(
          (e) => e.skuId == good.skuId
        );

        if (exchangeGood) {
          this.$message.info("已有该商品");
          return;
        }

        good.exchangeStock = 1;
        good.exchangePrice = 1;
        console.log("good", good);
        this.exchangeRelationGoodsSkuDtoList.push(this._.cloneDeep(good));
      }
    },
    showMainGoods() {
      this.modalType = "main";
      let obj = {};
      this.exchangeMainGoodsSkuDtoList.forEach(e => obj[e.skuId] = true);

      this.selectDic = obj;
      this.showGoodsModal = true;
    },
    showExchangeGoods() {
      this.modalType = "exchange";
      let obj = {};
      this.exchangeRelationGoodsSkuDtoList.forEach(e => obj[e.skuId] = true);

      this.selectDic = obj;
      this.showGoodsModal = true;
    },
    removeMainGood(row, index) {
      this.exchangeMainGoodsSkuDtoList.splice(index, 1);
    },
    removeExchangeGood(row, index) {
      this.exchangeRelationGoodsSkuDtoList.splice(index, 1);
    },
    checkForm() {
      if (
        !this.formData.startTime ||
        !this.formData.endTime ||
        (this.formData.validType == 1 && !this.dateTimeList)
      ) {
        this.$message.warning("请选择活动时间");
        return false;
      }

      if (!this.exchangeMainGoodsSkuDtoList.length) {
        this.$message.warning("请选择指定商品");
        return false;
      }

      if (!this.exchangeRelationGoodsSkuDtoList.length) {
        this.$message.warning("请选择换购商品");
        return false;
      }

      let incomp = this.exchangeRelationGoodsSkuDtoList.find(e => (e.exchangeStock == 0 || e.exchangePrice == 0));

      if(incomp) {
        this.$message.warning("请完善换购商品库存和价格");
        return false;
      }

      return true;
    },
    getTimeDiff(time) {
      let zeroTime = new Date(new Date().setHours(0,0,0,0)).valueOf();
      let timeObj = new Date(time);
      let hours = timeObj.getHours();
      let minute = timeObj.getMinutes();
      let getSeconds = timeObj.getSeconds();
      let currentTime = new Date(new Date().setHours(hours,minute,getSeconds,0)).valueOf();
      return (currentTime - zeroTime);
    },
    confirm() {
      if (!this.checkForm()) return;

      this.saveLoad = true;
      let userInfo = JSON.parse(localStorage.userInfo || "{}");

      if (this.type == "add") {
        this.formData.storeId =
          userInfo.storeList &&
          userInfo.storeList.length &&
          userInfo.storeList[0].storeId;
        this.formData.suppliersId = userInfo.suppliersId;
      }

      const pars = {
        ...this.formData,
        exchangeMainGoodsSkuDtoList: this.exchangeMainGoodsSkuDtoList,
        exchangeRelationGoodsSkuDtoList: this.exchangeRelationGoodsSkuDtoList,
      };

      if (this.formData.validType == 1) {
        pars.perDayStartTime = this.getTimeDiff(this.dateTimeList[0]);
          
        pars.perDayEndTime = this.getTimeDiff(this.dateTimeList[1]);
      } else {
        delete pars.perDayStartTime;
        delete pars.perDayEndTime;
      }

      if (this.type == "add") {
        addExchangeOrder(pars).then((res) => {
          this.saveLoad = false;
          if (res.code === 0) {
            this.$message.success("保存成功");
            this.$router.go(-1);
          } else {
            // this.$message.error(res.msg);
          }
        });
      } else {
        editExchange(pars).then((res) => {
          this.saveLoad = false;
          if (res.code === 0) {
            this.$message.success("保存成功");
            this.$router.go(-1);
          } else {
            // this.$message.error(res.msg);
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-exchange {
  background: #fff;
  border-radius: 10px;
  padding: 15px;
}
</style>
