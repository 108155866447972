import order from '../http/nb/order';
import axios from '@/http/axios'

const { exchangeOrder } = order;

export function exchangeOrderList(data) {
  return axios.request({
    url: exchangeOrder.list,
    method: 'post',
    data: data
  })
}

export function addExchangeOrder(data) {
  return axios.request({
    url: exchangeOrder.add,
    method: 'post',
    data: data
  })
}

export function updateStatus(data) {
  return axios.request({
    url: exchangeOrder.updateStatus,
    method: 'post',
    data: data
  })
}

export function skuGoodList(data) {
  return axios.request({
    url: exchangeOrder.skuGoodList,
    method: 'post',
    data: data
  })
}

export function exchangeDetail(data) {
  return axios.request({
    url: exchangeOrder.detail,
    method: 'get',
    params: data
  })
}

export function editExchange(data) {
  return axios.request({
    url: exchangeOrder.edit,
    method: 'post',
    data: data
  })
}
