var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "good-modal",
      attrs: {
        title: "选择商品",
        visible: _vm.value,
        width: "900px",
        "before-close": _vm.handleClose,
      },
    },
    [
      _c("div", [
        _c(
          "div",
          { staticClass: "nav" },
          [
            _c(
              "el-input",
              {
                staticStyle: { width: "220px" },
                attrs: { placeholder: "请输入商品名称", size: "mini" },
                model: {
                  value: _vm.goodsName,
                  callback: function ($$v) {
                    _vm.goodsName = $$v
                  },
                  expression: "goodsName",
                },
              },
              [
                _c("el-button", {
                  attrs: {
                    slot: "append",
                    type: "primary",
                    size: "mini",
                    icon: "el-icon-search",
                  },
                  on: { click: _vm.searchClick },
                  slot: "append",
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "box" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.tbLoad,
                    expression: "tbLoad",
                  },
                ],
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.tbList,
                  height: "450",
                  border: "",
                  size: "small",
                  "header-cell-style": {
                    background: "#d9dde1",
                    color: "#0d0202",
                  },
                },
              },
              [
                _c("el-table-column", {
                  attrs: { align: "center", prop: "id", label: "图片" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c("img", {
                            staticClass: "good-img",
                            attrs: { src: row.image, alt: "" },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "goodsName",
                    label: "商品名称",
                  },
                }),
                _c("el-table-column", {
                  attrs: { align: "center", prop: "skuName", label: "规格" },
                }),
                _c("el-table-column", {
                  attrs: { align: "center", prop: "sellPrice", label: "价格" },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "stockQuantity",
                    label: "库存",
                  },
                }),
                _c("el-table-column", {
                  attrs: { align: "center", prop: "id", label: "操作" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { disabled: row.checked, type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.selectGood(row)
                                },
                              },
                            },
                            [_vm._v(_vm._s(row.checked ? "已选择" : "选择"))]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c("Pagination", {
              attrs: {
                total: _vm.page.total,
                "page-size": _vm.page.pageSize,
                "current-page": _vm.page.page,
              },
              on: {
                "current-change": _vm.changePage,
                "size-change": _vm.changePageSize,
              },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }